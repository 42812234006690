import React, {useState,useRef,useEffect} from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GoogleMap from "../components/googlemap"
import "./index.css"
import SunCalc from "suncalc"
import moment from "moment-timezone"
import tzLookup from "tz-lookup"

const IndexPage = ({context}) => {

  const FIRST_DATE = '2023-10-01' // we start in October for context 
  const FIRST_DISPLAYED_DATE = '2023-12-20' // we start a day early
  const EARLIEST_TWEET = '2023-12-21'
  const LAST_DATE = '2024-07-01' // we show until summer

  const [userData,setUserData] = useState({user:null,tweets:[]})
  const [location, setLocation] = useState({
    latitude: '37.7349',
    longitude: '-122.441378'
  })

  const [editingMap, setEditingMap] = useState( false )

  const [timezone, setTimezone] = useState(false)

  const googleMapRef = useRef(null)

  const editMap = async () => {
    await setEditingMap(true)
    await googleMapRef.current.changeTheMap({
      center: {
        lat: parseFloat(location.latitude),
        lng: parseFloat(location.longitude)
      }
    })
  }
  const closeMap = () => {
    setEditingMap(false)
  }

  const handleBoundsChange = (e) => {
    updateAllLocation(
      e.lat.toString().substring(0,8),
      e.lng.toString().substring(0,8)
    )
  }

  const handleMapDrag = (e) => {
    updateAllLocation(
      e.center.lat().toString().substring(0,8),
      e.center.lng().toString().substring(0,8)
    )
  }

  const updateAllLocation = (latitude,longitude) => {
    setLocation({latitude,longitude})
    let tz = tzLookup(location.latitude,location.longitude)
    setTimezone(tz)
    storeLocationData(location,tz)
    setDates(getDates(tz,location))
  }

  const storeLocationData = async (location,timezone) => {
    return await fetch('/.netlify/functions/store_user', {
      body: JSON.stringify({
        location,
        timezone
      }),
      method: 'POST'
    }).then( async (response) => {
      // add the stored location to userdata
      let json = await response.json()
      setUserData({
        ...userData,
        user: {
          ...userData.user,
          location,
          timezone
        }
      })
      return json
    })
  }
 
  const fetchUserData = async () => {
    const res = await fetch("/.netlify/functions/read_userdata")
    const json = await res.json()
    if(!json.tweets) return
    // shim from fauna to postgres
    json.user.location = json.user.data.location
    json.user.timezone = json.user.data.timezone
    let tweets = {}
    for(let tweet of json.tweets) {
      tweet.key = moment.tz(tweet.when,'UTC').tz(json.user.timezone).format('YYYY-MM-DD')
      tweets[tweet.key] = tweet
    }
    await updateAllUserState(json.user,tweets)
  }

  const updateAllUserState = async (user,tweets) => {
    await setUserData({
      user: user,
      tweets
    })
    if(!user.location || !user.timezone) return
    await setLocation(user.location)
    await setTimezone(user.timezone)
    await setDates(getDates(user.timezone,user.location))
  }

  const [currentDate,setCurrentDate] = useState(FIRST_DISPLAYED_DATE)

  const [charCount,setCharCount] = useState(0)

  const handleTweetEdit = (e) => {
    setCharCount(e.target.value.length)
    // I literally can't believe this is valid JavaScript
    setUserData({
      ...userData,
      tweets: {
        ...userData.tweets,
        [currentDate]: {
          ...userData.tweets[currentDate],
          what: e.target.value
        }
      }
    })
  }

  const nextTweet = (e) => {
    if(e.shiftKey) {
      // previous date
      if (currentDate === EARLIEST_TWEET) return
      selectTweet(moment(currentDate).subtract(1,'day').format('YYYY-MM-DD'))
    } else {
      // next date
      if (currentDate === LAST_DATE) return
      selectTweet(moment(currentDate).add(1,'day').format('YYYY-MM-DD'))
    }  
  }

  const selectTweet = (date) => {
    setCurrentDate(date)
    if(userData.tweets[date]) {
      setCharCount(userData.tweets[date].what.length)
    } else {
      setCharCount(0)
    }    
  }

  const storeTweet = async (date) => {
    let day = findDate(dates,date)
    let when = moment.tz(day.sunset,'UTC') 
    // BUGFIX: sunCalc gets the day off by one if your longitude > 0
    if (parseFloat(location.longitude) > 0) {
      when.subtract(1,'day')
    }
    // if there's nothing, don't save it
    if(!userData.tweets[date]) return
    // if there is, save it
    return await fetch('/.netlify/functions/store_tweet', {
      body: JSON.stringify({
        what: userData.tweets[date].what,
        when: when.format(),
        id: userData.tweets[date].id
      }),
      method: 'POST'
    }).then( async (response) => {
      // set the ID into the tweet data so we send it next time
      let json = await response.json()
      userData.tweets[date].id = json.id
      return json
    })
  }

  const handleSwitching = async (e,date) => {
    // done with editing a tweet
    if(e.charCode === 13) {
      e.preventDefault()
      // move to the next tweet
      nextTweet(e)
      // save the tweet
      await storeTweet(date)
      return
    }
  }

  const inputRef = useRef(null)

  useEffect(() => {
    if(inputRef.current) {
      inputRef.current.focus()
    }
  }, [currentDate]);  

  const getDates = (timezone,location) => {
    console.log("Getting dates for timezone",timezone)
    console.log("and location",location)
    let start = moment.tz(FIRST_DATE+' 00',timezone)
    let end = moment.tz(LAST_DATE+' 00',timezone)
    let today = start
    let dates = []

    let getDaylightTimes = (times) => {
      let totalMs = moment(times.sunset).diff(moment(times.sunrise))          
      let hours = Math.floor(totalMs/(1000*60*60))
      let hoursInMs = hours * 1000*60*60
      let minutes = Math.floor((totalMs-hoursInMs)/(1000*60))
      let minutesInMs = minutes * 1000*60
      let seconds = Math.floor((totalMs-hoursInMs-minutesInMs)/1000)
      return {
        hours,
        minutes,
        seconds,
        totalMs
      }
    }

    let lookback = (from,days) => {
      let date = moment(from).subtract(days,'days')
      let day = findDate(dates,date.format('YYYY-MM-DD'))
      return day
    }

    let getMinuteChange = (from,to) => {
      let fM = moment(from)
      let tM = moment(to)
      let fromMinutes = (parseInt(fM.tz(timezone).format('h'))*60) + parseInt(fM.format('m'))
      let toMinutes = (parseInt(tM.tz(timezone).format('h'))*60) + parseInt(tM.format('m'))
      let diff = toMinutes - fromMinutes
      if (diff !== 0) {
        if (diff < 0) {
          return Math.ceil(diff)
        } else {
          return '+'+Math.floor(diff)
        }
      } else {
        return ''
      }
    }

    let getDaylightDifference = (today) => {
      let yesterday = lookback(today.key,1)
      if (!yesterday) return { hours: null, minutes: null, seconds:null }
      let totalMs = today.daylight.totalMs - yesterday.daylight.totalMs
      // is the day getting longer or shorter?
      // to do less math we just remember the sign and use positive integers
      let positive = true
      if (totalMs < 0) {
        positive = false
        totalMs = -totalMs
      }
      let hours = Math.floor(totalMs/(1000*60*60))
      let hoursInMs = hours * 1000*60*60
      let minutes = Math.floor((totalMs-hoursInMs)/(1000*60))
      let minutesInMs = minutes * 1000*60
      let seconds = Math.floor((totalMs-hoursInMs-minutesInMs)/1000)
      // humans care about minute changes, what are they?
      let sunriseMinuteChange = getMinuteChange(yesterday.sunrise,today.sunrise)
      let sunsetMinuteChange = getMinuteChange(yesterday.sunset,today.sunset)
      return {
        positive,
        hours,
        minutes,
        seconds,
        totalMs,
        sunriseMinuteChange,
        sunsetMinuteChange
      }
    }

    let firstEvents = {}
    let getNotes = (today) => {
      let notes = []
      let yesterday = lookback(today.day,1)
      // the first day in the sequence doesn't have a yesterday
      if (!yesterday) return notes
      if(!firstEvents.solstice) {
        if(today.daylight.totalMs > yesterday.daylight.totalMs) {
          firstEvents.solstice = true
          yesterday.notes.push("Solstice. Start here!")
        }  
      }
      // when does sunrise start getting earlier?
      if(!firstEvents.sunriseBackward) {
        if( (today.sunrise - yesterday.sunrise - (24*60*60*1000)) < 0) {
          firstEvents.sunriseBackward = true
          notes.push("Sunrise begins moving backwards")
        }        
      }
      // first total-minute moves
      if(!firstEvents.totalUp1) {
        if(today.daylightDifference.totalMs/(1000*60) > 1) {
          firstEvents.totalUp1 = true
          notes.push("First full extra minute")
        }
      }
      if(!firstEvents.totalUp1) {
        if(today.daylightDifference.totalMs/(1000*60) > 1) {
          firstEvents.totalUp1 = true
          notes.push("First full extra minute")
        }
      }
      if(!firstEvents.totalUp2) {
        if(today.daylightDifference.totalMs/(1000*60) > 2) {
          firstEvents.totalUp2 = true
          notes.push("First extra 2 minutes")
        }
      }
      if(!firstEvents.totalUp3) {
        if(today.daylightDifference.totalMs/(1000*60) > 3) {
          firstEvents.totalUp3 = true
          notes.push("First extra 3 minutes")
        }
      }
      // first clock-minute moves
      if(!firstEvents.sunriseBack1) {
        if (today.daylightDifference.sunriseMinuteChange === -1) {
          firstEvents.sunriseBack1 = true
          notes.push("First sunrise back by 1 minute")
        }
      }
      if(!firstEvents.sunriseBack2) {
        if (today.daylightDifference.sunriseMinuteChange === -2) {
          firstEvents.sunriseBack2 = true
          notes.push("First sunrise back by 2 minutes")
        }
      }
      if(!firstEvents.sunsetForward1 && firstEvents.solstice) {
        if (today.daylightDifference.sunsetMinuteChange === '+1') {
          firstEvents.sunsetForward1 = true
          notes.push("First sunset forward by 1 minute")
        }
      }
      if(!firstEvents.sunsetForward2) {
        if (today.daylightDifference.sunsetMinuteChange === '+2') {
          firstEvents.sunsetForward2 = true
          notes.push("First sunset forward by 2 minutes")
        }
      }
      if(parseInt(today.daylightDifference.sunriseMinuteChange) > 30) {
        notes.push(`The clocks changed! Yay!`)
      }
      return notes
    }

    let generateFacts = (today,allDays) => {

      let getLastDayThisLong = (today,allDays) => {
        for(let i = allDays.length-1; i > 0; i--) {
          let compareDay = allDays[i]
          if(today.daylight.totalMs < compareDay.daylight.totalMs) {
            return `Last day this long was ${compareDay.key}`
          }
        }
      }

      let diffCalc = (from) => {
        if(!from) return
        let totalGain = Math.round((today.daylight.totalMs - from.daylight.totalMs)/(1000*60))
        let minuteChange = getMinuteChange(from.sunset,today.sunset)
        return {
          minutes: minuteChange,
          total: totalGain
        }
      }

      let facts = []
      facts.push({
        label: "3 days ago",
        ...diffCalc(lookback(today.day,3))
      })
      facts.push({
        label: "5 days ago",
        ...diffCalc(lookback(today.day,5))
      })
      facts.push({
        label: "7 days ago",
        ...diffCalc(lookback(today.day,7))
      })
      facts.push({
        label: "10 days ago",
        ...diffCalc(lookback(today.day,10))
      })
      facts.push({
        label: "14 days ago",
        ...diffCalc(lookback(today.day,14))
      })
      facts.push({
        label: "30 days ago",
        ...diffCalc(lookback(today.day,30))
      })
      facts.push({
        label: "Since solstice",
        ...diffCalc(findDate(allDays,'2019-12-21'))
      })
      facts.push({
        label: "Since Jan 1",
        ...diffCalc(findDate(allDays,'2020-01-01'))
      })
      facts.push({
        label: "Since Feb 1",
        ...diffCalc(findDate(allDays,'2020-02-01'))
      })
      facts.push({
        isSpecial: true,
        label: getLastDayThisLong(today,allDays)
      })
      return facts
    }

    do {
        let times = SunCalc.getTimes(today.utc().toDate(), location.latitude, location.longitude )
        times.day = moment(times.sunrise)
        times.key = moment(times.sunrise).utc().format('YYYY-MM-DD')
        times.daylight = getDaylightTimes(times)
        times.daylightDifference = getDaylightDifference(times)
        times.notes = getNotes(times)
        times.funFacts = generateFacts(times,dates)
        if (!userData.tweets[times.key]) userData.tweets[times.key] = {
          what: ''
        }
        dates.push(times)
        today = today.add(24,'hours')
    } while (today.isBefore(end))    
    return dates
  }

  const [dates, setDates] = useState(false)

  const findDate = (dates,dateKey) => {
    return dates.find( (d) => {
      return d.key === dateKey
    })
  }

  useEffect(() => {
    fetchUserData()
  }, [])  

  return (
    <Layout>
      { (userData.user) ? (
        <>
          <SEO title="Your queued tweets" />      
          <h1>Hi @{userData.user.name}</h1>
          <div className="locationData">
            <div>
              <p>Showing times for latitude {location.latitude}, longitude {location.longitude}. Time zone: {timezone}
                { (editingMap) ? ( 
                  <button className="mapEdit" onClick={closeMap}>Done</button>
                ) : (
                  <button className="mapEdit" onClick={editMap}>Change location</button>
                )}
              </p>
            </div>
            { (editingMap) ? (
              <div>
                <div className="mapWindow">
                  <GoogleMap ref={googleMapRef} dragHandler={handleMapDrag} boundsHandler={handleBoundsChange}></GoogleMap>
                </div>                
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="calendar">
            <div className="row header">
              <div>Date</div>
              <div>Sunrise</div>
              <div>+/-</div>
              <div>Sunset</div>
              <div>+/-</div>
              <div>Daylight</div>
              <div>+/-</div>
              <div>Notes</div>
            </div>
            {
              dates && dates.map( (date) => {
                if(date.key < FIRST_DISPLAYED_DATE) return null
                return <div className="row" key={date.key} onClick={() => selectTweet(date.key)}>
                    <div className="date">
                      <div className="dow">{moment(date.sunrise).tz(timezone).format('dddd')}</div>
                      <div className="day">{moment(date.sunrise).tz(timezone).format('D MMM')}</div>
                    </div>
                    <div className="sunrise">
                      {moment(date.sunrise).tz(timezone).format('h:mm a')}
                    </div>
                    <div className="sunriseChange">
                      {date.daylightDifference.sunriseMinuteChange}
                    </div>
                    <div className="sunset">                      
                      {moment(date.sunset).tz(timezone).format('h:mm a')}
                    </div>
                    <div className="sunsetChange">
                      {date.daylightDifference.sunsetMinuteChange}
                    </div>
                    <div className="daylight">
                      {`${date.daylight.hours}h, ${date.daylight.minutes}m, ${date.daylight.seconds}s`}
                    </div>
                    <div className="daylightChange">{ (date.daylightDifference.totalMs) ? (
                      <>                        
                        { (date.daylightDifference.positive) ? '+' : '-' }
                        { (date.daylightDifference.hours) ? `${date.daylightDifference.hours}h, ` : '' }
                        { (date.daylightDifference.minutes) ? `${date.daylightDifference.minutes}m, ` : '' }
                        { (date.daylightDifference.seconds) ? `${date.daylightDifference.seconds}s ` : '0s' }
                      </>
                    ) : (
                      <></>
                    )}
                    </div>
                    <div className="notes">
                      <ul>                        
                      {
                        date.notes.map( (note) => {
                          return <li key={date.key+note}>{note}</li>
                        })
                      }
                      </ul>
                    </div>
                    <div className={(date.key === currentDate) ? "tweetCell expanded" : "tweetCell collapsed"}>
                      { (date.key === currentDate) ? (
                        <>
                          <div className="floating editor">
                            <textarea ref={inputRef} value={userData.tweets[currentDate] && userData.tweets[currentDate].what} onChange={handleTweetEdit} onKeyPress={(e) => handleSwitching(e,date.key)} />                          
                          </div>
                          <div className="charCount">{charCount}</div>
                          <button onClick={async (e) => {await storeTweet(date.key); setCurrentDate(false)}}>Save</button>
                          <div className="funFacts">
                            <h4>Gains since various dates:</h4>
                            <ul>
                              {
                                date.funFacts.map( facts => {
                                  if (facts.isSpecial && facts.label) return <li>{facts.label}</li>
                                  if (!facts.minutes) return
                                  return <li>{facts.label}: {facts.minutes} clock minutes, {facts.total} total</li>
                                })
                              }
                            </ul>
                          </div>
                        </>
                      ) : (
                        <div className="floating text">
                          <div>{userData.tweets[date.key] && userData.tweets[date.key].what}</div>
                        </div>
                      ) }
                    </div>
                  </div>
              })
            }
          </div>
        </>
      ) : (
        <>
          <SEO title="Welcome" />
          <h1>Welcome to Sun of Seldo!</h1>
          <p>The <a href="https://twitter.com/sunofseldo">@sunofseldo</a> account on <s>Twitter</s> Mastodon <s>tweets</s> posts encouraging things every day during the darkest days of winter, usually about how much daylight there is left. This tool helps <a href="https://twitter.com/seldo">@seldo</a> compose the tweets, and may help you if you want to do something similar!</p>
          <h2>How to use this app</h2>
          <p>(Please read all of these before starting!)</p>
          <p>1. <a href="/login">Log in with Twitter</a> using a Twitter account you intend to send the tweets from.</p>
          <p><b>Do not use your primary Twitter account</b>, this app is a side project and has not been security audited and I do not want responsibility for access keys to your personal Twitter account.</p>
          <p>2. Click "change location" and drag the map until you're exactly where you want it to be. Latitude (north-south) is what affects the length of the day, but longitude (east-west) will automatically select the right time zone for you.</p>
          <p>Be careful dragging the map too much; Twitter rate-limits this operation. If the app suddenly logs you out you've been rate-limited; wait 15 minutes and it will let you back in.</p>
          <p>3. Enter tweets! You can click on any date to change it. Hitting Enter will save a tweet and move to the next one. Hitting Shift+Enter will save the tweet and go to the previous one if you find it easier to work backwards.</p>
          <p>The app will give you suggestions about important events and milestones each day, and you can check @sunofseldo's tweets from previous years for examples of tweets people found particularly cheerful.</p>
        </>
      )}
    </Layout>
  )
}

export default IndexPage
